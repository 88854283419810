import {
    cssVal,
    getDataByCodePostal, getDataBySiren, hidden, isEmpty,
    newInput, texteForCivilite,
    tryToDate, visible,
} from "@/data/helpers";
import {def} from "@/data/dictionnaire";

export const changement = {
    title: "changement de commissaires aux comptes",
    texte: "<strong>{{societeGroup.societeDenomination}}</strong>[[ ({{societeGroup.sigle}})]] {{societeGroup.societeFormeJuridique}}, au capital de {{societeGroup.societeCapital}} €, Siège : {{siegeSocialGroup.societeAdresse}}[[, " +
        "{{siegeSocialGroup.societeAdresse2}}]] {{siegeSocialGroup.societeCodePostal}} {{siegeSocialGroup.societeVille}}, RCS : <span style='text-transform: uppercase'>{{societeGroup.societeRcsVille}}</span> N° {{societeGroup.societeRcs}}. <br>" +
        "Aux termes {{acteGroup.auTermeAssembleDecisionActe}} en date du {{acteGroup.dateDu}}, il a été décidé de nommer, à compter du {{acteGroup.dateEffet}}. - en qualité de Commissaire aux comptes titulaire : {{commissaireAuxComptesTitulaireGroup}}. {{commissaireAuxComptesSuppleantGroup}}. Formalités au RCS {{societeGroup.societeRcsVille}}.",
    script: (text, varText, attribute) => {
        let tmpText = "";
        switch (attribute) {
            case "commissaireAuxComptesTitulaireGroup":
                if(varText['commissaireAuxComptesTitulaireGroup.typePersonne'] === 'morale') {
                    tmpText = ` ${cssVal(varText['commissaireAuxComptesTitulaireGroup.denomination'])}${isEmpty(varText['commissaireAuxComptesTitulaireGroup.sigle']) ? '' : ' Sigle: '+ varText['commissaireAuxComptesTitulaireGroup.sigle']}, ${cssVal(varText['commissaireAuxComptesTitulaireGroup.formesSocietes'])}, RCS ${cssVal(varText['commissaireAuxComptesTitulaireGroup.lieuImmatriculation'])} ${cssVal(varText['commissaireAuxComptesTitulaireGroup.rcs'])}, au capital de ${cssVal(varText['commissaireAuxComptesTitulaireGroup.montantCapital'])} €, Siège : ${cssVal(varText['commissaireAuxComptesTitulaireGroup.adresse'])} ${cssVal(varText['commissaireAuxComptesTitulaireGroup.adresseSuite'])}, ${cssVal(varText['commissaireAuxComptesTitulaireGroup.codePostal'])} ${cssVal(varText['commissaireAuxComptesTitulaireGroup.ville'])}, représentée par ${cssVal(varText['commissaireAuxComptesTitulaireGroup.representerPar'])} en remplacement de ${cssVal(varText['commissaireAuxComptesTitulaireGroup.enRemplacementDe'])}`;
                } else {
                    tmpText = ` ${texteForCivilite(varText['commissaireAuxComptesTitulaireGroup.civilite'])} ${cssVal(varText['commissaireAuxComptesTitulaireGroup.prenom'])} ${cssVal(varText['commissaireAuxComptesTitulaireGroup.nom'])}, domicilié ${cssVal(varText['commissaireAuxComptesTitulaireGroup.adresse'])} ${cssVal(varText['commissaireAuxComptesTitulaireGroup.adresseSuite'])} ${cssVal(varText['commissaireAuxComptesTitulaireGroup.codePostal'])} ${cssVal(varText['commissaireAuxComptesTitulaireGroup.ville'])} en remplacement de ${cssVal(varText['commissaireAuxComptesTitulaireGroup.enRemplacementDe'])}`;
                }
                break;
            case "commissaireAuxComptesSuppleantGroup":
                if(varText['commissaireAuxComptesSuppleantGroup.typePersonneOuAucun'] === 'physique') {
                    tmpText = ` - en qualité de Commissaire aux comptes suppléant : ${texteForCivilite(varText['commissaireAuxComptesSuppleantGroup.civilite'])} ${cssVal(varText['commissaireAuxComptesSuppleantGroup.prenom'])} ${cssVal(varText['commissaireAuxComptesSuppleantGroup.nom'])}, domicilié ${cssVal(varText['commissaireAuxComptesSuppleantGroup.adresse'])} ${cssVal(varText['commissaireAuxComptesSuppleantGroup.adresseSuite'])} ${cssVal(varText['commissaireAuxComptesSuppleantGroup.codePostal'])} ${cssVal(varText['commissaireAuxComptesSuppleantGroup.ville'])} en remplacement de ${cssVal(varText['commissaireAuxComptesSuppleantGroup.enRemplacementDe'])}`;
                } else if(varText['commissaireAuxComptesSuppleantGroup.typePersonneOuAucun'] === 'morale') {
                    tmpText = ` - en qualité de Commissaire aux comptes suppléant : ${cssVal(varText['commissaireAuxComptesSuppleantGroup.denomination'])}${isEmpty(varText['commissaireAuxComptesSuppleantGroup.sigle']) ? '' : ' Sigle: '+ varText['commissaireAuxComptesSuppleantGroup.sigle']}, ${cssVal(varText['commissaireAuxComptesSuppleantGroup.formesSocietes'])}, RCS ${cssVal(varText['commissaireAuxComptesSuppleantGroup.lieuImmatriculation'])} ${cssVal(varText['commissaireAuxComptesSuppleantGroup.rcs'])}, au capital de ${cssVal(varText['commissaireAuxComptesSuppleantGroup.montantCapital'])} €, Siège : ${cssVal(varText['commissaireAuxComptesSuppleantGroup.adresse'])} ${cssVal(varText['commissaireAuxComptesSuppleantGroup.adresseSuite'])}, ${cssVal(varText['commissaireAuxComptesSuppleantGroup.codePostal'])} ${cssVal(varText['commissaireAuxComptesSuppleantGroup.ville'])}, représentée par ${cssVal(varText['commissaireAuxComptesSuppleantGroup.representerPar'])} en remplacement de ${cssVal(varText['commissaireAuxComptesSuppleantGroup.enRemplacementDe'])}`;
                } else {
                    tmpText = '';
                }
                break;
            case "acteGroup.auTermeAssembleDecisionActe":
                if(varText['acteGroup.auTermeAssembleDecisionActe'] === 'd\'un acte reçu par Me') {
                    tmpText = 'reçu par Me '+ cssVal(varText['acteGroup.nomNotaire']) +" notaire à "+ cssVal(varText['acteGroup.lieuNotaire']);
                } else {
                    tmpText = cssVal(varText['acteGroup.auTermeAssembleDecisionActe']);
                }
                break;
            case "acteGroup.dateEffet":
            case "acteGroup.dateDu":
                tmpText = cssVal(tryToDate(varText[attribute]));
                break;
            default:
                return undefined;
        }
        return tmpText;
    },
    model: {
        societeGroup: [{}],
        siegeSocialGroup: [{}],
        commissaireAuxComptesTitulaireGroup: [{}],
        commissaireAuxComptesSuppleantGroup: [{}],
    },
    schema: [
        {
            //0
            name: "societeGroup",
            label: "Société",
            type: "group",
            value: [{}],
            children: [
                newInput(def.rcs, '_required', {name: 'societeRcs',
                        on: {
                            change: async (val) => {
                                val = val.target.value.replaceAll(' ', '');
                                if (val.length !== 9) {
                                    return false;
                                }

                                const data = await getDataBySiren(val);
                                if(Object.keys(data).length === 0) {
                                    return false;
                                }

                                changement.model.societeGroup[0] = data.values.societe;
                                changement.model.siegeSocialGroup[0] = data.values.siegeSocial;

                                const dataCP = await getDataByCodePostal(data.values.siegeSocial.societeCodePostal, data.values.siegeSocial.ville);
                                changement.schema[1].children[3].options = dataCP.options;
                                changement.model.siegeSocialGroup[0].societeVille = dataCP.ville_slug;
                            }
                        }
                    }
                ),
                newInput(def.lieuImmatriculation, '_required', {name: 'societeRcsVille'}),
                newInput(def.denomination, '_required', {name: 'societeDenomination'}),
                newInput(def.sigle),
                newInput(def.formesSocietes, '_required', {name: 'societeFormeJuridique'}),
                newInput(def.montantCapital, '_required', {name: 'societeCapital'}),
            ]
        },
        {
            //1
            name: "siegeSocialGroup",
            label: "Ancien siège",
            type: "group",
            value: [{}],
            children: [
                newInput(def.adresse, '_required', {name: 'societeAdresse'}),
                newInput(def.adresseSuite, '_required', {name: 'societeAdresse2'}),
                newInput(def.codePostal, '_required', {
                    name: 'societeCodePostal',
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            changement.schema[1].children[3].options = data.options;
                            changement.model.siegeSocialGroup[0].societeVille = data.ville_slug;
                            changement.model.societeGroup[0].societeRcsVille = data.greffe;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect', {name: 'societeVille'}),
            ]
        },
        {
            //2
            name: "acteGroup",
            label: "Acte",
            type: "group",
            value: [{}],
            children: [
                newInput(def.auTermeAssembleDecisionActe, '_required', {
                    on: {
                        input: (val) => {
                            changement.schema[2].children[1] = val === "6" ? visible(changement.schema[2].children[1]) : hidden(changement.schema[2].children[1]);
                            changement.schema[2].children[2] = val === "6" ? visible(changement.schema[2].children[2]) : hidden(changement.schema[2].children[2]);
                        }
                    }
                }),
                newInput(def.nomNotaire, '_required', {}, 'hidden'),
                newInput(def.lieuNotaire, '_required', {}, 'hidden'),
                newInput(def.dateDu, '_required'),
                newInput(def.dateEffet, '_required'),
            ]
        },
        {
            //3
            name: "commissaireAuxComptesTitulaireGroup",
            label: "Commissaire aux comptes titulaire",
            type: "group",
            value: [{}],
            children: [
                newInput(def.typePersonne, '_required', {
                    on: {
                        input: (val) => {
                            changement.schema[3].children[1] = val === "physique" ? visible(changement.schema[3].children[1]) : hidden(changement.schema[3].children[1]);
                            changement.schema[3].children[2] = val === "physique" ? visible(changement.schema[3].children[2]) : hidden(changement.schema[3].children[2]);
                            changement.schema[3].children[3] = val === "physique" ? visible(changement.schema[3].children[3]) : hidden(changement.schema[3].children[3]);

                            changement.schema[3].children[4] = val === "morale" ? visible(changement.schema[3].children[4]) : hidden(changement.schema[3].children[4]);
                            changement.schema[3].children[5] = val === "morale" ? visible(changement.schema[3].children[5]) : hidden(changement.schema[3].children[5]);
                            changement.schema[3].children[6] = val === "morale" ? visible(changement.schema[3].children[6]) : hidden(changement.schema[3].children[6]);
                            changement.schema[3].children[7] = val === "morale" ? visible(changement.schema[3].children[7]) : hidden(changement.schema[3].children[7]);
                            changement.schema[3].children[8] = val === "morale" ? visible(changement.schema[3].children[8]) : hidden(changement.schema[3].children[8]);
                            changement.schema[3].children[9] = val === "morale" ? visible(changement.schema[3].children[9]) : hidden(changement.schema[3].children[9]);
                            changement.schema[3].children[14] = val === "morale" ? visible(changement.schema[3].children[14]) : hidden(changement.schema[3].children[14]);
                        }
                    }
                }),

                newInput(def.civilite, '_required'),//
                newInput(def.nom, '_required'),//
                newInput(def.prenom, '_required'),//

                newInput(def.rcs, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value.replaceAll(' ', '');
                            if (val.length !== 9) {
                                return false;
                            }

                            const data = await getDataBySiren(val);
                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            changement.model.commissaireAuxComptesTitulaireGroup[0] = Object.assign(changement.model.commissaireAuxComptesTitulaireGroup[0], data.values.all);

                            const dataCP = await getDataByCodePostal(data.values.siegeSocial.societeCodePostal, data.values.siegeSocial.ville);
                            changement.schema[3].children[13].options = dataCP.options;
                            changement.model.commissaireAuxComptesTitulaireGroup[0].ville = dataCP.ville_slug;
                        }
                    }
                }, 'hidden'),
                newInput(def.lieuImmatriculation, '_required', {}, 'hidden'),
                newInput(def.denomination, '_required', {}, 'hidden'),
                newInput(def.sigle, '', {}, 'hidden'),
                newInput(def.formesSocietes, '_required', {}, 'hidden'),
                newInput(def.montantCapital, '_required', {}, 'hidden'),

                newInput(def.adresse, '_required'),
                newInput(def.adresseSuite),
                newInput(def.codePostal, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            changement.schema[3].children[13].options = data.options;
                            changement.model.commissaireAuxComptesTitulaireGroup[0].ville = data.ville_slug;
                            changement.model.commissaireAuxComptesTitulaireGroup[0].lieuImmatriculation = data.greffe;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect'),
                newInput(def.representerPar, '_required', {}, 'hidden'),
                newInput(def.enRemplacementDe, '_required'),
            ]
        },
        {
            //4
            name: "commissaireAuxComptesSuppleantGroup",
            label: "Commissaire aux comptes suppléant",
            type: "group",
            value: [{}],
            children: [
                newInput(def.typePersonneOuAucun, '_required', {
                    on: {
                        input: (val) => {
                            changement.schema[4].children[1] = val === "physique" ? visible(changement.schema[4].children[1]) : hidden(changement.schema[4].children[1]);
                            changement.schema[4].children[2] = val === "physique" ? visible(changement.schema[4].children[2]) : hidden(changement.schema[4].children[2]);
                            changement.schema[4].children[3] = val === "physique" ? visible(changement.schema[4].children[3]) : hidden(changement.schema[4].children[3]);

                            changement.schema[4].children[4] = val === "morale" ? visible(changement.schema[4].children[4]) : hidden(changement.schema[4].children[4]);
                            changement.schema[4].children[5] = val === "morale" ? visible(changement.schema[4].children[5]) : hidden(changement.schema[4].children[5]);
                            changement.schema[4].children[6] = val === "morale" ? visible(changement.schema[4].children[6]) : hidden(changement.schema[4].children[6]);
                            changement.schema[4].children[7] = val === "morale" ? visible(changement.schema[4].children[7]) : hidden(changement.schema[4].children[7]);
                            changement.schema[4].children[8] = val === "morale" ? visible(changement.schema[4].children[8]) : hidden(changement.schema[4].children[8]);
                            changement.schema[4].children[9] = val === "morale" ? visible(changement.schema[4].children[9]) : hidden(changement.schema[4].children[9]);
                            changement.schema[4].children[14] = val === "morale" ? visible(changement.schema[4].children[14]) : hidden(changement.schema[4].children[14]);

                            changement.schema[4].children[10] = val === "autre" ? hidden(changement.schema[4].children[10]) : visible(changement.schema[4].children[10]);
                            changement.schema[4].children[11] = val === "autre" ? hidden(changement.schema[4].children[11]) : visible(changement.schema[4].children[11]);
                            changement.schema[4].children[12] = val === "autre" ? hidden(changement.schema[4].children[12]) : visible(changement.schema[4].children[12]);
                            changement.schema[4].children[13] = val === "autre" ? hidden(changement.schema[4].children[13]) : visible(changement.schema[4].children[13]);
                        }
                    }
                }),

                newInput(def.civilite, '_required'),
                newInput(def.nom, '_required'),
                newInput(def.prenom, '_required'),

                newInput(def.rcs, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value.replaceAll(' ', '');
                            if (val.length !== 9) {
                                return false;
                            }

                            const data = await getDataBySiren(val);
                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            changement.model.commissaireAuxComptesSuppleantGroup[0] = Object.assign(changement.model.commissaireAuxComptesSuppleantGroup[0], data.values.all);

                            const dataCP = await getDataByCodePostal(data.values.siegeSocial.societeCodePostal, data.values.siegeSocial.ville);
                            changement.schema[4].children[13].options = dataCP.options;
                            changement.model.commissaireAuxComptesSuppleantGroup[0].ville = dataCP.ville_slug;
                        }
                    }
                }, 'hidden'),
                newInput(def.lieuImmatriculation, '_required', {}, 'hidden'),
                newInput(def.denomination, '_required', {}, 'hidden'),
                newInput(def.sigle, '', {}, 'hidden'),
                newInput(def.formesSocietes, '_required', {}, 'hidden'),
                newInput(def.montantCapital, '_required', {}, 'hidden'),

                newInput(def.adresse, '_required'),
                newInput(def.adresseSuite, '_required'),
                newInput(def.codePostal, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            changement.schema[4].children[13].options = data.options;
                            changement.model.commissaireAuxComptesSuppleantGroup[0].ville = data.ville_slug;
                            changement.model.commissaireAuxComptesSuppleantGroup[0].lieuImmatriculation = data.greffe;
                        }
                    }
                }),
                newInput(def.ville, '_required'),
                newInput(def.representerPar, '_required', {}, 'hidden'),
                newInput(def.enRemplacementDe, '_required'),
            ]
        }
    ]
}